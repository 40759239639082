<template>
  <div class="integrator">
    <div class="integrator__header">
      <div
        class="text-xl text-darkblue font-bold whitespace-no-wrap font-SourceSansPro"
      >
        {{
          title === 1
            ? $t('integratorsTranslater.addParameter')
            : $t('integratorsTranslater.editParameter')
        }}
      </div>
      <div class="">
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
          @click="close"
        />
      </div>
    </div>
    <div class="integrator__area1 area">
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.name') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isNameMissing || isNameWrong ? 'input-error' : '']"
              v-model="integrator.name"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isNameMissing"
              >
                {{ $t('integratorsTranslater.enterName') }}
              </div>
            </transition>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isNameWrong"
              >
                {{ nameError }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.INN') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isInnMissing ? 'input-error' : '']"
              type="Number"
              v-model="integrator.inn"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isNameMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.numberCallCenter') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isPhoneMissing ? 'input-error' : '']"
              type="Number"
              v-model="integrator.phone"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isPhoneMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- Адрес -->
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.address') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isAddressMissing ? 'input-error' : '']"
              type="text"
              v-model="integrator.address"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isAddressMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- Услуги -->
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.services') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isServicesMissing ? 'input-error' : '']"
              type="text"
              v-model="integrator.services"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isServicesMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- Описание тарифа -->
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.tariffDescription') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isTariffDescriptionMissing ? 'input-error' : '']"
              type="text"
              v-model="integrator.tariff_description"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isTariffDescriptionMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.contactPerson') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isContactMissing ? 'input-error' : '']"
              v-model="integrator.contact_person"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isContactMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.email') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isEmailMissing ? 'input-error' : '']"
              type="email"
              v-model="integrator.email"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isEmailMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
        >
          {{ $t('integratorsTranslater.typeOrganization') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative w-full">
            <el-select :class="['w-full']" v-model="integrator.type.key">
              <el-option
                v-for="(item, index) in organizationType"
                :key="index"
                :value="item.key"
                :label="item.value"
              >
                {{ item.value }}
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="newIntegrator" class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.password') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isPasswordMissing ? 'input-error' : '']"
              type="password"
              v-model="integrator.password"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isPasswordMissing"
              >
                {{ $t('integratorsTranslater.password') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold"
          style="margin-bottom: 10px"
        >
          {{ $t('integratorsTranslater.smsTariff') }}
        </p>
        <div class="flex items-center justify-between">
          <div class="relative" style="width: 267px">
            <el-input
              class="mr-4"
              :class="[isSmsTariffMissing ? 'input-error' : '']"
              type="Number"
              v-model="integrator.sms_tariff"
            ></el-input>
            <transition name="el-zoom-in-top">
              <div
                class="text-notify text-xs top-full left-0 absolute"
                v-if="isNameMissing"
              >
                {{ $t('integratorsTranslater.fieldMiss') }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <!-- Платежная информация -->
    <div v-if="!newIntegrator" class="integrator__area2 area">
      <h2 class="integrator__title text-darkblue text-xl font-bold">
        {{ $t('integratorsTranslater.paymentInforamion') }}
      </h2>
      <div class="fields grid">
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.paySystem') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative">
              <el-select
                :class="[isNameMissing || isNameWrong ? 'input-error' : '']"
                v-model="integrator.billing.system"
              >
                <el-option
                  v-for="(item, index) in payModules"
                  :key="index"
                  :value="item.key"
                  :label="item.value"
                >
                  {{ item.value }}
                </el-option>
              </el-select>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isNameMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isNameWrong"
                >
                  {{ nameError }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.idShop') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="['billing', isMerchantMissing ? 'input-error' : '']"
                type="text"
                v-model="integrator.billing.merchant"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isMerchantMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.secretKey') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="['billing', isKeyMissing ? 'input-error' : '']"
                type="text"
                v-model="integrator.billing.key"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isKeyMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="integrator-file">
        <div
          :class="[
            'billing',
            'select-file',
            isPublicOfferPathMissing ? 'select-file--danger' : ''
          ]"
          @click="addFile"
        >
          <DocumentIcon v-if="!inputFile && !isPublicPath" />
          <DocumentFullIcon v-if="isPublicPath || inputFile" />
          <div v-if="inputFile" class="select-file__title">
            <p class="select-file__action-btn">
              <span>{{ $t('integratorsTranslater.change') }}</span>
              <span class="delete" @click="deleteFile">{{
                $t('integratorsTranslater.delete')
              }}</span>
            </p>
            <span class="select-file__file-name">{{ inputFile.name }}</span>
          </div>
          <a
            v-if="isPublicPath && !inputFile"
            target="_blank"
            class="select-file__title"
            @click="openFile"
            :href="publicPath"
          >
            {{ $t('integratorsTranslater.ofertaLoaded') }}
          </a>
          <p v-if="!inputFile && !isPublicPath" class="select-file__title">
            {{ $t('integratorsTranslater.moveOferta') }}
          </p>
          <span class="select-file__text">
            {{ $t('integratorsTranslater.documentMobileApp') }}
          </span>
        </div>
        <input
          ref="inputFile"
          class="input-file"
          type="file"
          @change="changeFile"
        />
      </div>
      <a class="link-partners" :href="linkPartners">{{
        setTextLinkPartners(linkPartners)
      }}</a>
    </div>

    <!-- Тариф по умолчанию для новых компаний -->
    <div v-if="!newIntegrator" class="integrator__area3 area">
      <h2 class="integrator__title text-darkblue text-xl font-bold">
        {{ $t('integratorsTranslater.tariffDefault') }}
      </h2>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
        >
          {{ $t('integratorsTranslater.typeAccount') }}
        </p>
        <div class="flex items-center justify-between mb-4">
          <div class="relative w-full">
            <el-select
              style="width: 80%"
              v-model="integrator.billing.default_tariff_type"
            >
              <el-option
                v-for="(item, index) in tariffType"
                :key="index"
                :value="item.key"
                :label="item.value"
              >
                {{ item.value }}
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="fields">
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.priceObject') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="[
                  'billing',
                  isDefaultUnitPriceMissing ? 'input-error' : ''
                ]"
                type="Number"
                v-model="integrator.billing.default_unit_price"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isDefaultUnitPriceMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.periodPayment') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="[
                  'billing',
                  isDefaultPeriodLengthDaysMissing ? 'input-error' : ''
                ]"
                type="Number"
                v-model="integrator.billing.default_period_length_days"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isDefaultPeriodLengthDaysMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.tryPeriod') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="[
                  'billing',
                  isDefaultTrialLengthDaysMissing ? 'input-error' : ''
                ]"
                type="Number"
                v-model="integrator.billing.default_trial_length_days"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isDefaultTrialLengthDaysMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Тариф по умолчанию для регистраций по QR -->
    <div v-if="!newIntegrator" class="integrator__area4 area">
      <h2 class="integrator__title text-darkblue text-xl font-bold">
        {{ $t('integratorsTranslater.tariffDefaultQR') }}
        <QRIcon class="title-icon" />
      </h2>
      <div class="flex flex-col w-full">
        <p
          class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
        >
          {{ $t('integratorsTranslater.typeAccount') }}
        </p>
        <div class="flex items-center justify-between mb-4">
          <div class="relative w-full">
            <el-select
              style="width: 80%"
              v-model="integrator.billing.qr_tariff_type"
            >
              <el-option
                v-for="(item, index) in tariffType"
                :key="index"
                :value="item.key"
                :label="item.value"
              >
                {{ item.value }}
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="fields">
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.priceObject') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="['billing', isQrUnitPriceMissing ? 'input-error' : '']"
                type="Number"
                v-model="integrator.billing.qr_unit_price"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isQrUnitPriceMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.periodPayment') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="[
                  'billing',
                  isQrPeriodLengthDaysMissing ? 'input-error' : ''
                ]"
                type="Number"
                v-model="integrator.billing.qr_period_length_days"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isQrPeriodLengthDaysMissing"
                >
                  {{ $t('integratorsTranslater.enterName') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-3"
          >
            {{ $t('integratorsTranslater.tryPeriod') }}
          </p>
          <div class="flex items-center justify-between">
            <div class="relative w-full">
              <el-input
                :class="[
                  'billing',
                  isQrTrialLengthDaysMissing ? 'input-error' : ''
                ]"
                type="Number"
                v-model="integrator.billing.qr_trial_length_days"
              ></el-input>
              <transition name="el-zoom-in-top">
                <div
                  class="text-notify text-xs top-full left-0 absolute"
                  v-if="isQrTrialLengthDaysMissing"
                >
                  {{ $t('integratorsTranslater.fieldMiss') }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'integrator__area5',
        'area',
        { 'integrator--add': newIntegrator }
      ]"
    >
      <button type="button" class="btn-cancel" @click="close">
        {{ $t('integratorsTranslater.cancel') }}
      </button>
      <button type="button" class="btn-save" @click="createOrEditIntegrator">
        {{ $t('btn.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { integratorsApi } from '@/api'

export default {
  components: {
    infoIcon: () => import('@/assets/svg-icons/info.svg'),
    DocumentIcon: () => import('./assets/icons/DocumentIcon.vue'),
    QRIcon: () => import('./assets/icons/QRIcon.vue'),
    DocumentFullIcon: () => import('./assets/icons/DocumentFullIcon.vue')
  },
  props: {
    title: Number,
    item: {
      type: Object,
      default: () => ({})
    },
    newIntegrator: {
      type: Boolean,
      default: false
    },
    payModules: {
      type: Array,
      default: () => []
    },
    organizationType: {
      type: Array,
      default: () => []
    },
    tariffType: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isNameMissing: false,
    isInnMissing: false,
    isPhoneMissing: false,
    isAddressMissing: false,
    isServicesMissing: false,
    isTariffDescriptionMissing: false,
    isEmailMissing: false,
    isContactMissing: false,
    isRegionMissing: false,
    valid: true,
    createIntegratorButton: false,
    integrator: {
      type: {
        key: '',
        type: '',
        value: ''
      },
      billing: {
        system: 'not_used',
        merchant: '',
        key: '',
        default_unit_price: 0,
        default_trial_length_days: 0,
        default_period_length_days: 0,
        qr_unit_price: 0,
        qr_trial_length_days: 0,
        qr_period_length_days: 0,
        public_offer_path: '',
        default_tariff_type: '',
        qr_tariff_type: ''
      }
    },
    errorField: '',
    isPasswordMissing: false,
    isSmsTariffMissing: false,
    isEmailWrong: false,
    isNameWrong: false,
    isPhoneWrong: false,
    isRegionesWrong: false,
    isInnWrong: false,
    nameError: '',
    emailError: '',
    phoneError: '',
    regionsError: '',
    innError: '',
    inputFile: null,
    inputFileUrl: null,
    isMerchantMissing: false,
    isKeyMissing: false,
    isPublicOfferPathMissing: false,
    isDefaultPeriodLengthDaysMissing: false,
    isDefaultTrialLengthDaysMissing: false,
    isDefaultUnitPriceMissing: false,
    isQrPeriodLengthDaysMissing: false,
    isQrTrialLengthDaysMissing: false,
    isQrUnitPriceMissing: false
  }),

  computed: {
    ...mapState('login', {
      regions: (state) => state.regions
    }),
    isPublicPath() {
      return !!this.integrator.billing.public_offer_path
    },
    publicPath() {
      return `${window.location.href}api_v1/${this.integrator.billing.public_offer_path}`
    },
    linkPartners() {
      return `${window.location.href}partners?${this.integrator.inn}`
    }
  },

  methods: {
    setTextLinkPartners(link) {
      return link.replace(/^https?:\/\//, '')
    },
    validation() {
      let isError = false
      if (!this.integrator.name.length) {
        this.isNameMissing = true
        isError = !this.integrator.name.length
      }
      if (!this.integrator.inn.length) {
        this.isInnMissing = true
        isError = !this.integrator.inn.length
      }
      if (!this.integrator.phone.length) {
        this.isPhoneMissing = true
        isError = !this.integrator.phone.length
      }
      if (!this.integrator.email.length) {
        this.isEmailMissing = true
        isError = !this.integrator.email.length
      }
      if (!this.integrator.email.length) {
        this.isEmailMissing = true
        isError = !this.integrator.email.length
      }
      if (!this.integrator.contact_person.length) {
        this.isContactMissing = true
        isError = !this.integrator.contact_person.length
      }
      if (this.newIntegrator && !this.integrator.password.length) {
        this.isPasswordMissing = true
        isError = !this.integrator.password.length
      }
      if (this.integrator.billing.system !== 'not_used') {
        for (const i in this.integrator.billing) {
          if (
            this.integrator.billing[i] === undefined ||
            this.integrator.billing[i].length === 0
          ) {
            this[`is${this.returnCorrectString(i)}Missing`] = true
            isError =
              !this.integrator.billing[i] || !this.integrator.billing[i].length
          }
        }
      }
      return isError
    },

    returnCorrectString(str) {
      const words = str.split('_')
      const formattedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      )
      return formattedWords.join('')
    },

    createOrEditIntegrator() {
      if (!this.validation()) {
        this.createIntegratorButton = true
        if (this.title === 1) {
          integratorsApi.createIntegrator(
            this.integrator,
            (response) => {
              this.createIntegratorButton = false
              this.$emit('close', {
                ...response.data
              })
            },
            (error) => {
              this.createIntegratorButton = false
              this.errorField = error.response.data.errors
              return this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: error.response.data.message
              })
            }
          )
          // this.$store.dispatch('login/ADD_REGION', this.item.regions)
        } else {
          integratorsApi.editIntegrator(
            this.integrator,
            (response) => {
              this.createIntegratorButton = false
              this.$emit('close', {
                ...response.data
              })
            },
            (error) => {
              this.createIntegratorButton = false
              this.errorField = error.response.data.errors
              return this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: error.response.data.message
              })
            }
          )
          // this.$store.dispatch('login/ADD_REGION', this.item.regions)
        }
      }
    },

    close() {
      this.$emit('close')
    },

    addFile() {
      const input = this.$refs.inputFile
      input.click((event) => {
        this.changeFile(event)
      })
    },

    deleteFile(event) {
      event.stopPropagation()
      this.integrator.billing.public_offer_path = ''
      this.inputFile = ''
    },

    changeFile(event) {
      const file = event.target.files[0]
      const fileSizeMb = Math.round(file.size / 1024)
      const fileSizeLimit = 1024 * 10
      if (fileSizeMb > fileSizeLimit) {
        return this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('integratorsTranslater.fileSize')
        })
      }
      this.inputFile = event.target.files[0]

      const newFormData = new FormData()
      newFormData.append('file', file)
      integratorsApi
        .addFile(this.item.id, newFormData)
        .then((response) => {
          this.inputFileUrl = response.data.url
          this.integrator.billing.public_offer_path = response.data.url
        })
        .catch((error) => {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error.response.data.message
          })
        })
    },

    openFile(event) {
      event.stopPropagation()
    }
  },

  created() {
    if (this.newIntegrator) {
      this.integrator = { ...this.item, ...this.integrator }
    } else {
      integratorsApi
        .getIntegrator(this.item.id)
        .then((response) => {
          if (response.data.billing.system === 'not_used') {
            this.integrator = {
              ...response.data,
              type: response.data.type,
              billing: this.integrator.billing
            }
          } else if (response.data.billing.system !== 'not_used') {
            this.integrator = response.data
          } else {
            this.integrator = {
              ...response.data,
              billing: this.integrator.billing
            }
          }
        })
        .catch((error) => {
          return this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: error
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.link-partners {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-top: 24px;
  color: #81abe1;
}
.skif-info-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 20px 10px 0 0;
  cursor: pointer;
  color: #2f80ed;
  font-size: 0;
  line-height: 0;
  outline: 0 !important;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: rgba(60, 94, 143, 1);
  }
}

.integrator {
  position absolute
  z-index 10
  display grid
  grid-template-columns 1fr
  grid-template-rows auto 1fr 1fr 1fr 1fr
  background #fff
  width 100%
  overflow-y scroll
  &__header {
    display flex
    justify-content space-between
    padding 22px 25px 16px 25px
    border-bottom 1px solid #CAD6DF
  }
  &__title {
    display flex
    align-items center
    padding-bottom 20px

    .title-icon {
      margin-left 11px
    }
  }
  &__area1 {
    display grid
    grid-template-columns 1fr 1fr
    column-gap 16px
    row-gap 20px
    padding 20px 25px 48px 25px
  }
  &__area2 {
    padding-top 0px
    padding 0 25px 48px 25px
  }
  &__area3 {
    padding 0 25px 48px 25px
  }
  &__area4 {
    padding 0 25px 48px 25px
  }
  &__area5 {
    display flex
    justify-content space-between
    padding 12px 25px 20px 25px
    border-top 1px solid #CAD6DF
  }
  .fields {
    display grid
    grid-template-columns 1fr 1fr 1fr
    gap 12px
    margin-bottom 20px
  }
  .select-file {
    position relative
    padding 12px 10px 9px 10px
    border 2px dashed #CAD6DF
    text-align center
    cursor pointer
    &__file-name {
      word-break break-word
    }
    &__title {
      display block
      font-style normal
      font-weight 600
      font-size 14px
      line-height 120%
      color #81ABE1
    }

    &__text {
      font-style normal
      font-weight 400
      font-size 12px
      line-height 120%
      color #A3AEB6
    }

    &__action-btn {
      position absolute
      top 8px
      right 16px
      font-weight 400
      font-size 14px
      line-height 16.8px
      color: #446C9D
      .delete {
        margin-left 12px
        color: #BF3131
      }
    }
    &--danger {
      border 2px dashed red
    }
  }

  .input-file {
    display none
  }

  .btn-cancel {
    width 268px
    padding 8px 0px
    font-weight 600
    font-size 14px
    line-height 16.8px
    color #fff
    text-align center
    background #C8D6DF
    border-radius 6px
  }

  .btn-save {
    width 268px
    padding 8px 0px
    font-weight 600
    font-size 14px
    line-height 16.8px
    color #fff
    background #446C9D
    border-radius 6px
  }

  &--add {
    position absolute
    bottom 0
    display grid
    grid-template-columns: 1fr 1fr
    gap 16px
  }
}
</style>
